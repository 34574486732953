<template>
  <Layout>
    <PageHeader :title="title" :items="state.items"/>
    <div class="card rounded-3">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h6 class="fs-16 mb-0">祝日設定ログ</h6>
        <b-button :disabled="state.loadingAction" variant="primary" class="waves-effect waves-light pt-2 pb-2"
                  @click="seedHoliday"
                  style="width: 180px">
          <div class="flex  justify-content-center">
                <span v-if="state.loadingAction" class="d-flex align-items-center mr-2">
                  <Loading/>
                </span>
            祝日手動設定
          </div>
        </b-button>
      </div>
      <div class="table-responsive m-auto" style="width: 80%">
        <TableCommon
          ref="tableFacility"
          :columns="state.column"
          :endpoint="ADMIN_API_PATH.ADMIN_HOLIDAY"
          :query="{sortBy:'createdAt',sortOrder:'DESC'}"
        />
      </div>
    </div>
  </Layout>
</template>

<script>
// import TableCommon from "@/components/common/TableCommon.vue";
import TableCommon from "@/components/common/TableCommonVue3.vue";
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {ADMIN_API_PATH, DATE_FORMAT, ROLES} from "@/constants";
import {h, reactive, ref} from "vue";
import {facilityService} from "@/services";
import Loading from "@/components/Loading.vue";
import * as moment from "moment";
import i18n from "@/i18n";

export default {
  computed: {
    ROLES() {
      return ROLES
    },
    ADMIN_API_PATH() {
      return ADMIN_API_PATH
    }
  },
  page: {
    title: '施設情報管理',
    meta: [{name: "description"}],
  },
  components: {Loading, PageHeader, Layout, TableCommon},
  data() {
    return {};
  },
  watch: {},
  mounted() {
    this.loadHoliday();
  },
  methods: {
    reDataTable() {
      this.$refs.tableFacility.refreshTable()
    },

    async loadHoliday() {

    },

    async seedHoliday() {
      this.state.loadingAction = true
      await facilityService.seedHoliday().then(e => {
        this.state.loadingAction = false
        this.reDataTable()
      })
    }
  },
  setup() {
    const state = reactive({
      column: [
        {
          sortable: false,
          label: 'No',
          type: 'stt',
          //maxWidth: 50,
          dataClass: 'status-stt',
          getShowColumn: (hasItem) => hasItem,
          renderCell: (api, rowIndex) => {
            return h('span', {class: 'status-stt'}, rowIndex + 1)
          }
        },
        {
          key: 'day',
          sortable: true,
          renderCell: (row) => {
            return h('span', {class: 'status-stt'}, moment(row.day).format(DATE_FORMAT))
          },
          label: '時刻',
          maxWidth: 200
        },
        {
          key: 'status',
          sortable: true,
          renderCell: (row) => {
            return h('span', {class: 'status-stt'}, row.status === true ? 'Success' : 'Fail')
          },
          label: "ステータス",
          maxWidth: 200
        },
        {
          key: 'exception',
          sortable: true,
          label: "メッセージ",
          maxWidth: 200
        }
      ],
      items: [
        {
          text: i18n.global.t('t-setting_'),
          active: true
        },
        {
          text: '祝日設定ログ',
          active: true,
        },
      ],
      loadingAction: false,

    })

    const title = ref(i18n.global.t('t-setting_'))
    return {state, title}
  }
};
</script>

<style>
.status-stt {
  width: 50px !important;
}

</style>
